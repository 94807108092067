import './style';
import Home from './components/home'

export default function App() {
	return (
		<div
			id="App"
		>
			<Home />
		</div>
	)
}
